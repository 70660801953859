import React from 'react';
import './css/swiper.css';
import './css/slider.css'; // Ensure this file exists at the specified path
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/bundle';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';
import { Navigation } from 'swiper/modules';

const Slider = ({ sliderdata }) => {
    const Slides = sliderdata?.map((item, index) => {
        return (
            <SwiperSlide key={index} className="slider-slide" style={{ marginBottom: '20px' }}>
            <img 
                src={item?.image} 
                loading='lazy' 
                className="w-100 fixed-height-image" 
                alt="" 
                style={{ objectFit: 'cover', width: '100%', height: '100%' }}
            />
            </SwiperSlide>
        );
    });

    return (
        <>
            <Swiper
                style={{
                    '--swiper-navigation-color': '#fff',
                    '--swiper-pagination-color': '#fff',
                }}
                loop={true}
                spaceBetween={10}
                navigation={true}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                }}
                modules={[Autoplay, Navigation]}
                className="mySwiper2"
            >
                {Slides}
            </Swiper>
        </>
    );
};

export default Slider;
